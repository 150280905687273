var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "ProductDetailPop:edit",
        "destroy-on-close": "",
        btnLoading: _vm.btnLoading,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "" }, slot: "default" },
        [
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "封面" } },
            [
              _c("img", {
                staticStyle: { width: "90px", height: "120px" },
                attrs: { src: _vm.reCurRow.cover, alt: "avatar" },
              }),
            ]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "上线作品名称" } },
            [_vm._v(_vm._s(_vm.reCurRow.onlineName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "原文名" } },
            [_vm._v(_vm._s(_vm.reCurRow.originName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "业务" } },
            _vm._l(_vm.businessTypes, function (item) {
              return _c(
                "div",
                { key: item.id },
                [
                  _c("a-tag", [
                    _vm._v("\n          " + _vm._s(item.value) + "\n        "),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _vm.reCurRow.timedPublish
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "发布时间" } },
                [_vm._v(_vm._s(_vm.reCurRow.publishTime))]
              )
            : _vm._e(),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "上线时间" } },
            [_vm._v(_vm._s(_vm.reCurRow.onlineTime))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "上线平台" } },
            [_vm._v(_vm._s(_vm.reCurRow.platform))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "外链地址" } },
            [_vm._v(_vm._s(_vm.reCurRow.link))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "详细信息" } },
            [
              _c("span", {
                staticClass: "detail-span",
                domProps: { innerHTML: _vm._s(_vm.reCurRow.detail) },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "form", attrs: { model: _vm.formData, rules: _vm.rules } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                {
                  staticClass: "upload-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "cover",
                  },
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("\n          封面：\n          "),
                    _c("div", { staticClass: "upload-span" }, [
                      _vm._v("3：4比例的竖版图片"),
                    ]),
                  ]),
                  _c(
                    "a-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        name: "file",
                        "list-type": "picture-card",
                        "show-upload-list": false,
                        action: _vm.ossUploadUrl,
                        data: _vm.ossUploadData,
                        beforeUpload: _vm.beforeUpload,
                      },
                      on: {
                        change: (info) => {
                          _vm.handleChangeUpload(info, "cover")
                        },
                      },
                    },
                    [
                      _vm.formData.cover
                        ? _c("img", {
                            staticStyle: { width: "90px", height: "120px" },
                            attrs: { src: _vm.formData.cover, alt: "avatar" },
                          })
                        : _c(
                            "div",
                            [
                              _c("a-icon", {
                                attrs: {
                                  type: _vm.avatarLoading ? "loading" : "plus",
                                },
                              }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v("上传封面"),
                              ]),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "上线作品名称",
                    prop: "onlineName",
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入上线作品名" },
                    model: {
                      value: _vm.formData.onlineName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "onlineName", $$v)
                      },
                      expression: "formData.onlineName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "原文名",
                    prop: "originName",
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.originName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "originName", $$v)
                      },
                      expression: "formData.originName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "业务",
                    prop: "businessTypes",
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        mode: "multiple",
                        showArrow: "",
                        "not-found-content": null,
                        "filter-option": false,
                        placeholder: "请选择业务",
                        "option-filter-prop": "label",
                        "default-value": _vm.formData.businessTypes,
                        Value: _vm.formData.businessTypes,
                      },
                      on: { change: _vm.selectChange },
                    },
                    _vm._l(
                      _vm.dictList.filter((d) => d.status),
                      function (busines) {
                        return _c(
                          "a-select-option",
                          {
                            key: busines.itemText,
                            attrs: { label: busines.itemText },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(busines.itemText) +
                                "\n          "
                            ),
                          ]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "定时发布",
                    prop: "isPublishTime",
                  },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.formData.timedPublish,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "timedPublish", $$v)
                        },
                        expression: "formData.timedPublish",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: true } }, [
                        _vm._v("\n            是\n          "),
                      ]),
                      _c("a-radio", { attrs: { value: false } }, [
                        _vm._v("\n            否\n          "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "发布时间",
                    prop: "publishTime",
                  },
                },
                [
                  _c("a-date-picker", {
                    attrs: {
                      format: "YYYY-MM-DD HH:mm:ss",
                      disabled: !_vm.formData.timedPublish,
                      valueFormat: "YYYY-MM-DD HH:mm:ss",
                      "show-time": {
                        format: "HH:mm:ss",
                        defaultValue: _vm.moment("23:59:59", "HH:mm:ss"),
                      },
                      placeholder: "请选择发送时间",
                    },
                    model: {
                      value: _vm.formData.publishTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "publishTime", $$v)
                      },
                      expression: "formData.publishTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "上线时间",
                    prop: "onlineTime",
                  },
                },
                [
                  _c("a-date-picker", {
                    attrs: {
                      format: "YYYY-MM-DD",
                      valueFormat: "YYYY-MM-DD",
                      placeholder: "请选择上线时间",
                    },
                    model: {
                      value: _vm.formData.onlineTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "onlineTime", $$v)
                      },
                      expression: "formData.onlineTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "上线平台",
                    prop: "platform",
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入平台名称" },
                    model: {
                      value: _vm.formData.platform,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "platform", $$v)
                      },
                      expression: "formData.platform",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "外链地址",
                    prop: "link",
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "link", $$v)
                      },
                      expression: "formData.link",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "详细信息",
                    prop: "detail",
                  },
                },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "请输入详细信息",
                      "auto-size": { minRows: 3, maxRows: 5 },
                    },
                    model: {
                      value: _vm.formData.detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "detail", $$v)
                      },
                      expression: "formData.detail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
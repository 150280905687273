<template>
  <!-- 作品详情配置抽屉 -->
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    :permission="'ProductDetailPop:edit'"
    @show="show"
    destroy-on-close
    :btnLoading="btnLoading"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div slot>
      <info-item :label-width="labelWidth" label="封面">
        <img :src="reCurRow.cover" style="width:90px;height:120px" alt="avatar" />
      </info-item>
      <info-item :label-width="labelWidth" label="上线作品名称">{{ reCurRow.onlineName }}</info-item>
      <info-item :label-width="labelWidth" label="原文名">{{ reCurRow.originName }}</info-item>
      <info-item :label-width="labelWidth" label="业务">
        <div v-for="item in businessTypes" :key="item.id">
          <a-tag>
            {{ item.value }}
          </a-tag>
        </div>
      </info-item>
      <info-item v-if="reCurRow.timedPublish" :label-width="labelWidth" label="发布时间">{{
        reCurRow.publishTime
      }}</info-item>
      <info-item :label-width="labelWidth" label="上线时间">{{ reCurRow.onlineTime }}</info-item>
      <info-item :label-width="labelWidth" label="上线平台">{{ reCurRow.platform }}</info-item>
      <info-item :label-width="labelWidth" label="外链地址">{{ reCurRow.link }}</info-item>
      <info-item :label-width="labelWidth" label="详细信息">
        <span class="detail-span" v-html="reCurRow.detail"></span>
      </info-item>
    </div>
    <div slot="form">
      <a-form-model ref="form" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item class="upload-item" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="cover">
          <span slot="label">
            封面：
            <div class="upload-span">3：4比例的竖版图片</div>
          </span>
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="ossUploadUrl"
            :data="ossUploadData"
            :beforeUpload="beforeUpload"
            @change="
              info => {
                handleChangeUpload(info, 'cover')
              }
            "
          >
            <img v-if="formData.cover" :src="formData.cover" style="width:90px;height:120px" alt="avatar" />
            <div v-else>
              <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传封面</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="上线作品名称"
          class="first-form-item"
          prop="onlineName"
        >
          <a-input v-model="formData.onlineName" placeholder="请输入上线作品名"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="原文名"
          class="first-form-item"
          prop="originName"
        >
          <a-input v-model="formData.originName" placeholder="请输入名称"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="业务"
          class="first-form-item"
          prop="businessTypes"
        >
          <a-select
            mode="multiple"
            showArrow
            :not-found-content="null"
            :filter-option="false"
            placeholder="请选择业务"
            option-filter-prop="label"
            @change="selectChange"
            :default-value="formData.businessTypes"
            :Value="formData.businessTypes"
          >
            <a-select-option
              :label="busines.itemText"
              v-for="busines in dictList.filter(d => d.status)"
              :key="busines.itemText"
            >
              {{ busines.itemText }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="定时发布" prop="isPublishTime">
          <a-radio-group v-model="formData.timedPublish">
            <a-radio :value="true">
              是
            </a-radio>
            <a-radio :value="false">
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="发布时间"
          class="first-form-item"
          prop="publishTime"
        >
          <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            :disabled="!formData.timedPublish"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :show-time="{ format: 'HH:mm:ss', defaultValue: moment('23:59:59', 'HH:mm:ss') }"
            placeholder="请选择发送时间"
            v-model="formData.publishTime"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="上线时间"
          class="first-form-item"
          prop="onlineTime"
        >
          <a-date-picker
            format="YYYY-MM-DD"
            valueFormat="YYYY-MM-DD"
            placeholder="请选择上线时间"
            v-model="formData.onlineTime"
          />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="上线平台"
          class="first-form-item"
          prop="platform"
        >
          <a-input v-model="formData.platform" placeholder="请输入平台名称"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="外链地址"
          class="first-form-item"
          prop="link"
        >
          <a-input v-model="formData.link" placeholder="请输入名称"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="详细信息"
          class="first-form-item"
          prop="detail"
        >
          <a-textarea v-model="formData.detail" placeholder="请输入详细信息" :auto-size="{ minRows: 3, maxRows: 5 }" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { getAction, putAction, postAction } from '@/api/manage'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import JEditor from '@/components/jeecg/JEditor'
export default {
  mixins: [DrawerMixins, UploadFileToOssMixins],
  components: {
    JEditor
  },
  data() {
    let validatePublishTime = (rule, value, callback) => {
      if (this.formData.timedPublish) {
        if (!value) {
          callback(new Error('请选择发布时间'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    let validateBusinessTypes = (rule, value, callback) => {
      if (this.formData.businessTypes && this.formData.businessTypes.length) {
        if (!value) {
          callback(new Error('请选择业务'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请选择业务'))
      }
    }
    let validateCover = (rule, value, callback) => {
      if (!this.formData.cover) {
        callback(new Error('请上传封面'))
      } else {
        callback()
      }
    }
    return {
      isPublishTime: 2,
      url: {
        list: '',
        edit: '/productDetail',
        add: '/productDetail'
      },
      rules: {},
      business: [
        {
          value: '1',
          label: '韩翻中'
        },
        {
          value: '2',
          label: '中翻日'
        },
        {
          value: '4',
          label: '版权代理'
        },
        {
          value: '8',
          label: '上色'
        },
        {
          value: '16',
          label: '原创'
        }
      ],
      rules: {
        cover: [{ validator: validateCover, message: '请上传封面', trigger: 'change' }],
        businessTypes: [{ validator: validateBusinessTypes, trigger: 'change' }],
        onlineName: [{ required: true, message: '请输入上线作品名', trigger: 'blur' }],
        platform: [{ required: true, message: '请输入平台', trigger: 'blur' }],
        publishTime: [{ validator: validatePublishTime, trigger: 'change' }]
      },
      avatarLoading: false,
      labelCol: {
        span: 7
      },
      formData: {
        businessTypes: []
      },
      labelWidth: '120',
      wrapperCol: {
        span: 16
      },
      businessTypes: [],
      reCurRow: {}
    }
  },
  props: {
    isSync: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String,
      default: () => 'GLOBAL'
    },
    relationId: {
      type: String,
      default: () => ''
    },
    dictList: {
      type: Array,
      default: () => []
    },
    isTongChou: {
      type: Boolean,
      default: false
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {
    curRow: {
      deep: true, //true为进行深度监听,false为不进行深度监听
      handler(newVal) {
        this.businessTypes = newVal.businessTypes || []
        this.reCurRow = { ...newVal.productionOnline }
        this.reCurRow.businessTypes = this.businessTypes.map(item => item.value)
      }
    }
  },
  methods: {
    async beforeUpload(file) {
      if (parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2) > 1) {
        file = await this.compressImg(file)
      }
      return this.ossPolicy(file.name)
    },
    compressImg(file) {
      const that = this
      var files
      var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2)
      var read = new FileReader()
      read.readAsDataURL(file)
      return new Promise(function(resolve, reject) {
        read.onload = function(e) {
          var img = new Image()
          img.src = e.target.result
          img.onload = function() {
            // 默认按比例压缩
            var w = this.width / 2
            var h = this.height / 2
            w = w.toFixed(2)
            h = h.toFixed(2)
            // 生成canvas
            var canvas = document.createElement('canvas')
            var ctx = canvas.getContext('2d')
            var base64
            // 创建属性节点
            canvas.setAttribute('width', w)
            canvas.setAttribute('height', h)
            ctx.drawImage(this, 0, 0, w, h)
            if (fileSize < 1) {
              // 如果图片小于一兆 那么压缩0.5
              base64 = canvas.toDataURL(file['type'], 0.5)
            } else if (fileSize > 1 && fileSize < 2) {
              // 如果图片大于1M并且小于2M 那么压缩0.5
              base64 = canvas.toDataURL(file['type'], 0.5)
            } else {
              // 如果图片超过2m 那么压缩0.2
              base64 = canvas.toDataURL(file['type'], 0.2)
            }
            // 回调函数返回file的值（将base64编码转成file）
            files = that.dataURLtoFile(base64, file.name) // 如果后台接收类型为base64的话这一步可以省略
            resolve(files)
          }
        }
      })
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    selectChange(value) {
      this.formData.businessTypes = value
    },
    show() {
      console.log('显示...', { 'this.curRow': this.curRow })
    },
    edit() {
      this.isEdit = true
      this.isSaved = false
      console.log('编辑...')
      this.formData = this.reCurRow
      console.log({ 'this.formData': this.formData })
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.startEdit()
    },
    handleChangeUpload(info, type) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        var img = new Image()
        img.src = params.filePath
        img.onload = async () => {
          if (img.height > img.width) {
            this.formData.cover = params.filePath
            this.removeOssFilePolicy(info.file)
            this.avatarLoading = false
            this.$forceUpdate()
          } else {
            this.$message.error('请上传竖版图片')
            this.avatarLoading = false
          }
        }
      }
    },
    initForm() {
      this.reCurRow = {}
      this.formData = {
        businessTypes: []
      }
      this.businessTypes = []
    },
    save() {
      this.btnLoading = true
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.onlineTime && this.formData.onlineTime.length < 11) {
            this.formData.onlineTime = this.formData.onlineTime + ' 00:00:00'
          }
          if (!this.isEdit) {
            const res = await postAction('/productionOnline', this.formData)
            if (res.code == 200) {
              this.$message.success('新建成功')
              this.isSaved = true
              this.btnLoading = false
              this.closed()
            } else {
              this.btnLoading = false
              this.$message.error(res.msg || res.message)
            }
          } else {
            const res = await putAction('/productionOnline', this.formData)
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.isSaved = true
              this.btnLoading = false
              this.closed()
            } else {
              this.btnLoading = false
              this.$message.error(res.msg || res.message)
            }
          }
        } else {
          this.btnLoading = false
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-upload {
  min-width: 90px !important;
  min-height: 120px !important;
}
/deep/ .upload-item .ant-form-item-label > label::after {
  content: '' !important;
}
.upload-item .upload-span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
}
.detail-span {
  white-space: pre-line;
}
</style>
